<template>
  <div>
    <el-divider content-position="left" class="tittle">Shipment List</el-divider>
    <!-- 搜索 -->
    <div class="topSearch">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" ref="ruleForm" label-position="top">
        <el-form-item prop="tracking_id">
          <el-input v-model="searchForm.tracking_id" placeholder="Tracking Id" @keyup.native.enter="onSubmit"
            style="width:140px;"></el-input>
        </el-form-item>
        <el-form-item prop="city">
          <el-input style="width:140px;" v-model="searchForm.city" placeholder="City"
            @keyup.native.enter="onSubmit"></el-input>
        </el-form-item>
        <el-form-item prop="zip">
          <el-input v-model="searchForm.zip" placeholder="zip code" @keyup.native.enter="onSubmit"
            style="width:140px;"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="sort">
        <el-select v-model="searchForm.sort" placeholder="Start deg" style="width:160px;">
          <el-option v-for="(val, k) in sortOption" :key="k" :label="val" :value="k">
          </el-option>
        </el-select>
      </el-form-item> -->
        <el-form-item id="range">
          <el-select v-model="searchForm.from_angle" filterable placeholder="Start deg" @focus="selectFoucs"
            @blur="selectBlur">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span style="color:#DCDFE6;"> To </span>
          <el-select v-model="searchForm.to_angle" filterable placeholder="End deg" @focus="selectFoucs"
            @blur="selectBlur">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="searchBtn">
          <el-button type="primary" @click="onSubmit">Search</el-button>
          <el-button type="default" @click="resetForm('ruleForm')">Reset</el-button>
        </el-form-item>
        <el-form-item class="searchBtn" v-show="role == 1">
          <el-button class="add" @click="createJob">+ Create Blocks</el-button>
        </el-form-item>
        <el-form-item class="searchBtn" v-show="role == 1">
          <el-button class="add" @click="putintojob()">Put Into Block</el-button>
        </el-form-item>
      </el-form>
      <el-select v-model="sort" @change="sortChange" id="sort">
        <el-option v-for="(val, k) in sortOption" :key="k" :label="val" :value="k">
        </el-option>
      </el-select>
    </div>
    <!-- 表格 -->
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" stripe
      @selection-change="handleSelectionChange">
      <el-table-column :type="role == 1 ? 'selection' : ''" width="55" :selectable="select">
      </el-table-column>
      <el-table-column label="Block Sn" min-width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.job_sn }}</span>
          <span v-if="scope.row.job_sn != '' && role == 1" class="iconfont icon-shanchu1"
            @click="removetojob(scope.row.job_id, scope.row.tracking_id)"></span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Create At" min-width="160" align="center">
      </el-table-column>
      <el-table-column prop="shelf_name" label="Shelf Name" align="center" :show-overflow-tooltip="true" min-width="105">
        <template slot-scope="scope">
          <span v-if="scope.row.shelf_name">{{ scope.row.shelf_name }}</span>
          <span v-else>N/A</span>
        </template>
      </el-table-column>
      <el-table-column prop="tracking_id" label="Tracking Id" width="120" align="center">
      </el-table-column>
      <el-table-column prop="dimension" label="Dimension(IN)" align="center" min-width="125">
        <template slot-scope="scope">
          {{ scope.row.length }}*{{ scope.row.width }}*{{ scope.row.height }}
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="Weight(LB)" align="center" min-width="100"></el-table-column>
      <el-table-column label="Address" min-width="200" :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          <div class="dian">{{ scope.row.to_street1 }}</div>
          <div class="dian">{{ scope.row.to_street2 }}</div>
          <div class="dian">
            {{ scope.row.to_city }}&nbsp;{{ scope.row.to_state }}&nbsp;{{ scope.row.to_zip }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="direction" label="Direction" align="center" min-width="85">
        <template slot-scope="scope">
          <svg :style="{ 'transform': 'rotate(' + scope.row.angle + 'deg)' }" t="1684735556105" class="icon red"
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13946" width="24" height="24">
            <path
              d="M512.512 59.904l124.928 230.4c3.072 5.12-1.536 12.288-7.68 11.264l-117.76-13.312-117.76 13.312c-6.144 0.512-10.752-6.14399999-7.68-11.264l125.952-230.4z"
              p-id="13947" fill="#f56c6c"></path>
            <path d="M486.912 964.096l0-729.088 51.2 1e-8L538.112 964.096z" p-id="13948" fill="#f56c6c"></path>
          </svg>
        </template>
      </el-table-column>
      <el-table-column prop="angle" label="Clockwise Angle" align="center" min-width="135">
      </el-table-column>
    </el-table>

    <!-- 上一页和下一页 -->
    <div class="btn">
      <el-button type="primary" @click="reduce" :disabled="current_page == 1">previous page
      </el-button>
      <el-button type="primary" @click="add" :disabled="current_page == total_pages || total_pages == 0">next
        page</el-button>
    </div>
    <!-- 选择jobid弹框 -->
    <el-dialog title="Select Block Id" :visible.sync="dialog" width="50%">
      <el-table :data="jobidlist" style="width: 100%" height="250" border show-overflow-tooplip="false">
        <el-table-column label="Block Id">
          <template slot-scope="scope">
            <el-radio v-model="radio" :label="scope.row.barcode"
              @change.native="getTemplateRow(scope.row.barcode, scope.row.id)"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="putintoSure">Submit</el-button>
        <el-button size="mini" @click="putintoCnacel">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  shipments,
  createjobs,
  addshipment,
  jobids,
  delshipment,
  config
} from '@/api/user.js'
import local from '@/utils/local'
export default {
  inject: ['reload'],
  data () {
    return {
      options: [],
      radio: '',
      job_id: '',
      searchForm: {
        tracking_id: '',
        city: '',
        zip: '',
        from_angle: '',
        to_angle: ''
      },
      sort: '1',
      tableData: [],
      selectTractingId: [],
      dialog: false, // 弹框显示隐藏
      jobidlist: [],
      role: '',
      sortOption: {},
      current_page: 1, // 当前页
      total_pages: 0 // 总页数
    }
  },
  created () {
    this.role = local.get('role')
    this.getlist()
    for (let i = 0; i < 361; i++) {
      this.options.push({ value: i, label: i })
    }
    this.getSort()
  },
  methods: {
    async getSort () {
      const data = await config()
      // console.log(data)
      this.sortOption = data.shipment_sort_options
    },
    sortChange (e) {
      this.current_page = 1
      this.sort = e
      this.getlist()
    },
    selectFoucs (e) {
      const cont = document.querySelector('#range .el-form-item__content')
      cont.style.borderColor = '#409EFF'
    },
    selectBlur (e) {
      const cont = document.querySelector('#range .el-form-item__content')
      cont.style.borderColor = '#DCDFE6'
    },
    // 移除出job
    removetojob (jobid, tracking) {
      const removeArr = []
      removeArr.push(tracking)
      this.$confirm(
        'Are you sure you want to remove the shipment from block?',
        'Tips',
        {
          cancelButtonText: 'Cancel',
          confirmButtonText: 'OK',
          type: 'warning'
        }
      )
        .then(async () => {
          const data = await delshipment({
            job_id: jobid,
            tracking_ids: removeArr
          })
          if (data.status === 200) {
            this.$message({
              message: 'Removed successfully',
              type: 'success'
            })
            this.getlist()
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
          }
        })
        .catch(() => { })
    },
    // 取消放入job
    putintoCnacel () {
      this.radio = ''
      this.job_id = ''
      this.dialog = false
    },
    // 确认放入job
    async putintoSure () {
      const data = await addshipment({
        job_id: this.job_id,
        tracking_ids: this.selectTractingId
      })
      // console.log(data)
      if (data.status === 200) {
        this.$message({
          message: 'Put into block successfully',
          type: 'success'
        })
        this.radio = ''
        this.job_id = ''
        this.dialog = false
        this.getlist()
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    getTemplateRow (code, id) {
      this.job_id = id
      this.radio = code
    },
    select (row) {
      return row.job_sn === ''
    },

    // 重置
    resetForm () {
      this.$refs.ruleForm.resetFields()
      this.reload()
    },
    // 创建job
    async createJob () {
      const data = await createjobs({
        tracking_ids: this.selectTractingId
      })
      if (data.status === 200) {
        this.$message({
          message: 'Create successfully',
          type: 'success'
        })
        this.getlist()
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    // 放入job
    putintojob () {
      this.getjobidlist()
      this.dialog = true
    },
    // 获取jobid 列表
    async getjobidlist () {
      const data = await jobids()
      // console.log(data)
      this.jobidlist = data
    },
    // 监听选择
    handleSelectionChange (val) {
      const selectarrid = []
      for (let i = 0; i < val.length; i++) {
        selectarrid.push(val[i].tracking_id)
      }
      // console.log(selectarrid)
      this.selectTractingId = selectarrid
    },
    // 渲染请求
    async getlist () {
      const data = await shipments({
        page: this.current_page,
        tracking_id: this.searchForm.tracking_id,
        city: this.searchForm.city,
        zip: this.searchForm.zip,
        sort: this.sort,
        from_angle: this.searchForm.from_angle,
        to_angle: this.searchForm.to_angle
      })
      this.tableData = data.data
      this.current_page = data.current_page
      this.total_pages = data.page_count
    },
    // 提交搜索
    onSubmit () {
      this.current_page = 1
      this.getlist()
    },
    // 上一页
    reduce () {
      this.current_page--
      this.getlist()
    },
    // 下一页
    add () {
      this.current_page++
      this.getlist()
    }
  }
}
</script>

<style lang="less" scpoed>
.dian {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#sort {
  width: 220px;
}

.topSearch {
  display: flex;
  justify-content: space-between;
}

.el-divider__text.is-left {
  font-size: 30px;
  font-weight: bold;
  color: #333;
}

.tittle {
  margin-bottom: 60px;
}

/deep/.el-table tr th {
  background-color: #f5f7fa;
}

.add {
  background-color: #009688;
  color: #fff;
}

.btn {
  margin-top: 50px;
  text-align: right;
}

.icon-shanchu1 {
  padding-left: 5px;
  cursor: pointer;

  &:hover {
    color: #f56c6c;
  }
}

.el-message-box__wrapper>.el-message-box>.el-message-box__btns>button {
  float: right;
  margin-left: 10px;
}

#range {
  .el-form-item__content {
    border: 1px solid #DCDFE6;
    border-radius: 4px;

    .el-input__inner {
      border-color: transparent;
      width: 110px;
    }

    .el-input__suffix {
      display: none;
    }

    &:hover {
      border-color: #c0c4cc;
    }
  }
}
</style>
